@import '../../marketplace.css';

.sectionContent {
  width: 100%;
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
  padding: 0 36px 0 36px;
  margin: 50px auto 50px auto;
  @media (--viewportMedium) {
    margin: 93px auto 93px auto;
    max-width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 800px;
  }

  @media (--viewportXLarge) {
    max-width: 900px;
    padding: 0;
  }
}


.title{
  font-size: 28px;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 36px;
  }
}

.list{
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  align-items: stretch;
  margin-top: 78px;
  text-align: center;
  font-size: 36px;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.listItem{
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.listItemText{
  margin: 10px 0 0 0;
  @media (--viewportMedium) {
    margin-top: 28px;
  }
}


.jointext{
  margin: 60px auto 0 auto;
  text-align: center;
  max-width: 920px;
}

.buttonWrapper{
  text-align: center;
  margin-top: 45px
}

.toBuy{
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;
  animation-delay: 0.8s;
}

.joinWrapper{
  margin-top: 0
}



.testimony{
  background-color: #F5F5F5
}

.testimonyList{
  display: grid;
  text-align: center;
  grid-template-columns: 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 40px;
  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.testimonyDescription{
  margin-top: 24px;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  font-weight: 200
}

.testimonyPeople{
  font-size: 16px;
}

.testimonyPic{
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 100%;
  object-position: top;
}
.testimonyPeopleName{
  color: #666666
}


.plans{
  position: relative;
  margin-top: 0;
}

.plans:before{
  content: "";
  position: absolute;
  left: 0;
  bottom:0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(90deg,#2DC000,#1FA860);
  z-index: -1;
}


.plans .sectionContent{
  max-width: 1320px;
}




.video{
  width: 420px;
  height: 240px
}

.modalVideo{
  padding: 2px;
  background: white;
  top: 0;
  bottom: 0;
  position: relative;
  margin: auto;
}

.modalVideo > button{
  left: 0
}

.actionsButtons{
  display: flex;
}

.videoButton_text{
  margin-right: 8px;
}
.videoButton{
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  border: 0;
  text-align: left;
  justify-content: flex-start;
  color: white;
}

.modalButtonContainer{
  display: flex;
  padding-top: 50px;
  justify-content: center;
}

@media (--viewportMedium) {
  .video{
    width: 680px;
    height: 380px

  }
}
