.planCardsMobile {
    border-radius: 14px;
    /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem; */
    position: relative;

    @media (--viewportLargeWithPaddings) {
        display: none;
    }
}

.planCardsDesktop {
    display: none;

    @media (--viewportLargeWithPaddings) {
        border-radius: 14px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        /* gap: 1rem; */
        position: relative;
        margin-bottom: 0px;
        padding: 0px 20px;
    }
}

.plansContainer {
    text-align: center;
    background-color: #f8f8f8;
    padding: 20px;
}

.plansContainer > :nth-child(1) {
    text-align: center;
    margin-bottom: 0px;
    color: #242424;
    font-size: 30px;
    margin-bottom: 10px;
}

.plansContainer > :nth-child(2) {
    margin-top: 5px;
    font-size: 18px;
    color: #242424;
    margin-bottom: 50px;
}

.planCards > div > div > div > ul > li {
    width: 330px !important;
}

.carouselNextButton,
.carouselBackButton {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 0;
    padding: 0 10px;
    transition: all 0.2s ease-in;
    outline: 0;
    cursor: pointer;
    opacity: 0.7;
}

.carouselNextButton:hover,
.carouselBackButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease-in;
    opacity: 1;
}

.carouselNextButton:active,
.carouselBackButton:active {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.carouselNextButton {
    right: 0px;
}

.carouselBackButton {
    left: 0px;
}

.carouselChevron {
    font-size: 40px;
    color: gray;
}

.planCardOrg {
    background-color: white;
    border-radius: 10px;
    box-shadow: 3px 3px 12px -7px rgb(0 0 0 / 63%);
    padding: 20px;
    margin: 0px 50px;
    text-align: center;
    max-width: 320px;

    @media (--viewportSmall) {
        /* width: 310px; */
        display: grid;
        /* grid-template-rows: 20px 115px 30px 30px 100px 50px 80px; */
        grid-template-rows: 20px 95px 30px 40px 90px 30px 70px;
        align-items: center;
        margin: 0px 10px;
        margin-bottom: 60px;
    }

    @media (--viewportMedium) {
        grid-template-rows: 10px 85px 25px 40px 85px 30px 70px;
        grid-gap: 12px;
    }

    @media (--viewportLarge) {
        grid-template-rows: 20px 95px 30px 40px 90px 30px 70px;
        grid-gap: 0px;
        margin: auto;
    }
}

.planCardOrg > img {
    margin: auto;
}

.planCard {
    background-color: white;
    border-radius: 10px;
    box-shadow: 3px 3px 12px -7px rgb(0 0 0 / 63%);
    padding: 20px;
    margin: 0px 50px;
    text-align: center;
    max-width: 320px;

    @media (--viewportSmall) {
        /* width: 310px; */
        display: grid;
        /* grid-template-rows: 20px 115px 30px 30px 100px 50px 80px; */
        grid-template-rows: 20px 95px 30px 40px 90px 30px 70px;
        align-items: center;
        margin: 0px 10px;
        margin-bottom: 60px;
    }

    @media (--viewportMedium) {
        grid-template-rows: 10px 85px 25px 40px 85px 30px 70px;
        grid-gap: 12px;
    }

    @media (--viewportLarge) {
        grid-template-rows: 20px 95px 30px 40px 90px 30px 70px;
        grid-gap: 0px;
    }
}

.planCard > p {
    padding: 0px 20px;

    @media (--viewportSmall) {
        padding: 0px;
    }
}

.planCard > :nth-child(4) {
    margin: 0px;
    align-self: flex-start;
}

.planCard > img {
    margin: auto;
    height: 50px;
}

.planCard > :nth-child(2) {
    font-size: 18px;
    /* margin-top: 30px; */
}

.whiteButton {
    color: #ff7a00;
    border: solid 2px #ff7a00;
    height: 41px;
    border-radius: 5px;
    /* width: 60%; */
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.orangeButton {
    border: none;
    background: #ff7a00;
    color: white;
    height: 41px;
    border-radius: 5px;
    /* width: 60%; */
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
